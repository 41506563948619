
import { Options, mixins } from "vue-class-component";
import DcService from "../../service/DcService.js";
import Toaster from "../../helpers/Toaster";
import { ActionTypes } from "../../store";
import UtilityOptions from "../../mixins/UtilityOptions";
import { StreamBarcodeReader } from "vue-barcode-reader";

interface IItemList {
  receiptNo: string;
  qty: number;
  description: string;
  reason: string;
}

interface IResList {
  msg: string;
  receiptNo: string;
  status: string;
}

@Options({
  title: "Scan Items",
  components: { StreamBarcodeReader },
})
export default class ScanItems extends mixins(UtilityOptions) {
  private lists: IItemList[] = [];
  private resLists: IResList[] = [];
  private toast;
  private audioContext;
  private goToFirstLink = 0;
  private receiptService;
  private submitted = false;
  private scanDialog = false;
  private scanDriverConfirmDialog = false;
  private driverLocation = "Store";
  private roleName = "";
  private locationTitle = "Are you at Store Or Plant?";

  private item = {
    itemID: "",
    qty: 0,
    description: "None",
    reason: "",
  };

  private descriptionList = [
    { value: "None" },
    { value: "Missing Items" },
    { value: "Damage Items" },
  ];

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    //this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  created() {
    this.receiptService = new DcService();
    this.toast = new Toaster();
    this.audioContext = new AudioContext();

  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.getRoleName();

    // this.lists.push({
    //   receiptNo: "32212300001",
    //   qty: 1,
    //   description: "",
    //   reason: "",
    // });
    // this.lists.push({
    //   receiptNo: "32212300002",
    //   qty: 2,
    //   description: "",
    //   reason: "",
    // });
    // this.lists.push({
    //   receiptNo: "32212300003",
    //   qty: 3,
    //   description: "",
    //   reason: "",
    // });
  }

  onDecode(text) {
   
    const s = text.split("-");

    this.playBeep();
    
    if(s.length == 2 && s[0].length == 14)
    {
      if (this.item.itemID != s[0] && s[0] != "") {

        
        this.item.itemID = s[0];
        this.item.qty = Number(s[1]);
        this.saveScanItem();
      }
    }
  }

  onLoaded() {
    // alert(`Ready to start scanning bar codes`);
  }

  onError() {
    // alert(`Ready to start scanning error`);
  }

  saveScanItem() {
    const r = this.lists.filter((e) => e.receiptNo == this.item.itemID);

    if (r.length == 0) {
      this.scanDialog = true;
    }
  }

  clearItem() {
    this.item = {
      itemID: "",
      description: "None",
      reason: "",
      qty: 0,
    };
  }

  deleteItem(d) {
    this.lists.splice(this.lists.indexOf(d), 1);
    this.toast.showSuccess("Receipt Removed Successfully");
  }

  get getTotalItems() {
    var i = 0;

    this.lists.forEach((e) => {
      i = i + Number(e.qty);
    });
    return i;
  }

  get getTotalReceipt() {
    const i = this.lists.length;
    return i;
  }

  saveItem() {
    this.receiptService
      .saveItem(this.lists, this.driverLocation)
      .then((res) => {
        if (res.alert == "info") {
          this.lists = [];
        }
        this.toast.handleResponse(res);
        this.resLists = [];

        if (this.roleName == "Admin" || this.roleName == "Driver") {
          this.scanDriverConfirmDialog = true;
          this.locationTitle = "Are you at Store Or Plant?";
        }
        else if (this.roleName == "Plant") 
        {
          this.scanDriverConfirmDialog = true;
          this.locationTitle = "Are you Receiving or Completed items?";
        }
      });
  }
  
  checkItem() {
    this.receiptService
      .checkItem(this.lists, this.driverLocation)
      .then((res) => {

        if(res.alert == 'info')
        {
          let r = JSON.parse(res.msg);
          this.resLists = r;

        }
        else
        {
          this.toast.handleResponse(res);
        }

      });
  }

  getRoleName() {
    this.receiptService.getUserRoleName().then((res) => {
      this.roleName = res.roleName;

      if (this.roleName == "Admin" || this.roleName == "Driver") {
        this.scanDriverConfirmDialog = true;
        this.locationTitle = "Are you at Store Or Plant?";
      }
      else if (this.roleName == "Plant") 
      {
         this.scanDriverConfirmDialog = true;
         this.locationTitle = "Are you Receiving or Completed items?";
      }
    });
  }

  adjustQty(op) {
    if (op == "add") {
      this.item.qty++;
    } else {
      if (this.item.qty > 0) {
        this.item.qty--;
      }
    }
  }

  confirmedReceipt() {
    this.lists.push({
      receiptNo: this.item.itemID,
      qty: Number(this.item.qty),
      description: this.item.description,
      reason: this.item.reason,
    });
    this.toast.showInfo("Scanned Successfully");
    this.clearItem();
    this.scanDialog = false;
  }

  closeConfirmation(loc) {
    this.scanDriverConfirmDialog = false;
    this.driverLocation = loc;
  }

  playBeep() {
  const oscillator = this.audioContext.createOscillator();
  oscillator.frequency.setValueAtTime(2000, this.audioContext.currentTime);
  oscillator.connect(this.audioContext.destination);
  oscillator.start();
  oscillator.stop(this.audioContext.currentTime + 0.1);
}

  checkResMsg(receiptNo)
  {
    let r = {
      status: '',
      receiptNo: '',
      msg:"",
    }

    let i =  this.resLists.filter(f => f.receiptNo == receiptNo);

    if(i.length > 0)
    {
      r.status    =  i[0].status;
      r.receiptNo =  i[0].receiptNo;
      r.msg       =  i[0].msg; 
    }

    return r;

  }

  get checkItemListStatus()
  {
    let r = this.resLists.filter(f => f.status == "No").length;

    if(r > 0)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
}
