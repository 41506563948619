
import { Options, mixins } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import DcService from "../service/DcService.js";
import UtilityOptions from "../mixins/UtilityOptions";

@Options({
  props: {
    OrderHistoryReceipt: Object,
  },
  watch: {
    OrderHistoryReceipt(obj) {

      this.openDialog();

      this.productDialog = obj.status;

      if(obj.receiptID != "")
      {
        this.loadReceipt(obj.receiptID);
      }
    },
  },
  emits: ["updatePreviewStatus"],
})


export default class OrderHistoryReceipt extends mixins(UtilityOptions) {
  private toast;
  private productDialog = false;
  private receiptService;
  private list = [];

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.toast = new Toaster();
    this.receiptService = new DcService();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.productDialog = true;
  }

  closeDialog() {
    this.$emit("updatePreviewStatus", {});
    this.productDialog = false;
  }


  loadReceipt(receiptID)
  {
    this.receiptService.singleHistory(receiptID).then((res) => {
        if (res != null)
        {
          this.list = res;
        }
    });
  }
}
