
import { Options, mixins } from "vue-class-component";
import { ActionTypes } from "../../store";
import UtilityOptions from "../../mixins/UtilityOptions";
import Toaster from "../../helpers/Toaster";
import DcService from "../../service/DcService.js";
import VoucherService from "../../service/VoucherService.js";
import OrderHistoryReceipt from "../../components/OrderHistoryReceipt.vue";

@Options({
  title: "Admin Receipt Tracking",
  components: {OrderHistoryReceipt},
})
export default class AdminOrderTracking extends mixins(UtilityOptions) {
  private lists = [];
  private userLists = [];
  private toast;
  private dialogTitle = "";
  private statement = "";
  private receiptID = "";
  private receiptService;
  private productDialog = false;
  private editDialog = false;
  private previewPosReceipt = false;
  private menuBar = false;
  private submitted = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [{ label: "Admin Receipt Tracking" }];
  private filterList;
  private itemFilter = {
    keyword: "",
    userID: "2",
    filterType: "this_month",
    date1: "",
    date2: "",
    location: "Store",
  };

  private locationList = [
    { value: "Store" },
    { value: "Plant" },
  ]; 
  
  private descriptionList = [
    { value: "None" },
    { value: "Missing Items" },
    { value: "Damage Items" },
  ];

  private item = {
    id: 0,
    location: "",
  };

  private voucherService;

  //DEFAULT METHOD OF TYPE SCRIPT
  created() {
    this.receiptService = new DcService();
    this.voucherService = new VoucherService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadUserList();
    this.loadList();
    this.getTheStoreFilterList();
  }

  openFilterDialog() {
    this.dialogTitle = "Filter Scans";
    this.itemFilter.keyword = "";
    this.productDialog = true;
  }

  //FETCH THE DATA FROM SERVER
  loadList() {
    this.productDialog = false;
    this.receiptService.adminList(this.itemFilter).then((data) => {
      this.lists = data.list;
      this.statement = data.statement;
    });
  }

  loadUserList() {
    this.productDialog = false;
    this.receiptService.userList().then((data) => {
      this.userLists = data.list;
    });
  }
  get currency() {
    return this.store.getters.getCurrency;
  }

  clearDates() {
    this.itemFilter.date1 = "";
    this.itemFilter.date2 = "";
  }

  clearFilterName() {
    this.itemFilter.filterType = "None";
  }

  getTheStoreFilterList() {
    this.voucherService.getFilterList().then((res) => {
      this.filterList = res.datesList;
    });
  }

  openMenu(data) {
    this.item.id = data.id;
    this.item.location = data.location;
    this.menuBar = true;
  }

  deleteReceipt() {
    this.menuBar = false;
    this.receiptService.deleteReceipt(this.item.id).then((res) => {
      this.loadList();
      this.toast.handleResponse(res);
    });
  }

  updateReceipt() {
    this.menuBar = false;
    this.editDialog = false;
    this.receiptService.updateReceiptDriver(this.item).then((res) => {
      this.loadList();
      this.toast.handleResponse(res);
    });
  }

  updatePreviewStatus() {
      this.previewPosReceipt = false;
  }

  previewDialog(receiptID) {
      this.receiptID = receiptID;
      this.previewPosReceipt = true;
  }
}
