
import { Options, mixins } from "vue-class-component";
import PosService from "../../service/PosService.js";
import ProfilerService from "../../service/ProfilerService.js";
import ChartService from "../../service/ChartService.js";
import Toaster from "../../helpers/Toaster";
import AutoComplete from "primevue/autocomplete";
import SearchFilter from "../../components/SearchFilter.vue";
import PosPreviewReceipt from "../../components/PosPreviewReceipt.vue";
import PaymentScreen from "../../components/PaymentScreen.vue";
import { ActionTypes } from "../../store";
import UtilityOptions from "../../mixins/UtilityOptions";

interface PaymentListType {
    paymentType: string;
    accountNo: string;
    terminalId: string;
    authCode: string;
    transId: string;
    transStatus: string;
    transType: string;
    transDate: string;
    transTime: string;
    transAmount: number;
    transTotalAmount: number;
    transRef: string;
    entryMode: string;
    hostResponse: string;
    giftCardRef: string;
    cardBalance: string;
    tendered: number;
    change: number;
    roundOff: number;
    bankID: number;
}

@Options({
    title: 'Transactions',
    components: {
        PaymentScreen,
        AutoComplete,
        SearchFilter,
        PosPreviewReceipt
    }
})
export default class TransactionReceipt extends mixins(UtilityOptions) {
    private lists = [];

    private selectedReceiptData = {
        paymentTransactions: []
    };

    private profilerList = [];
    private accountHeadList = [];
    private totalAmountStatement = "Total Amount Received";
    private statement = "";
    private menuBar = false;
    private paymentDialog = false;
    private dialogTitle = "";
    private toast;
    private goToFirstLink = 0;
    private receiptNO = "";
    private previewHeading = "";
    private receiptID = 0;
    private receiptService;
    private profilerService;
    private chartService;
    private previewTransactionDialog = false;
    private previewPosReceipt = false;
    private productDialog = false;
    private filterDialog = false;
    private submitted = false;
    private statusDialog = false;
    private checkPagination = true;
    private totalRecords = 0;
    private totalBalanceReceipt = 0;
    private limit = 0;
    private home = { icon: "pi pi-home", to: "/" };
    private items = [{ label: "Transactions" }];

    private counterEntry = [
        {
            accountID: 0,
            accountHead: "",
            amount: 0,
            type: "Debit"
        }
    ];

    private receiptTypes = [
        { key: "Transfer Stocks", value: "TRN" },
        { key: "Arrived Stocks", value: "ASR" },
        { key: "Purchase Stocks", value: "PUR" },
        { key: "Purchase Return", value: "RPU" }
    ];

    private itemFilter = {
        keyword: "",
        filterType: "None",
        storeID: 0,
        date1: "",
        date2: "",
        type: "TRN"
    };

    private paymentList: PaymentListType[] = [];

    private item = {
        id: 0,
        profileID: 0,
        selectedProfile: "",
        type: "",
        status: ""
    };

    //CALLING WHEN PAGINATION BUTTON CLICKS
    onPage(event) {
        this.loadList(event.first);
    }

    //DEFAULT METHOD OF TYPE SCRIPT
    created() {
        this.receiptService = new PosService();
        this.profilerService = new ProfilerService();
        this.chartService = new ChartService();
        this.toast = new Toaster();
    }

    //CALLNING AFTER CONSTRUCTOR GET CALLED
    mounted() {
        this.loadList(0);
    }

    openFilterDialog() {
        this.dialogTitle = "Filter Receipt";
        this.filterDialog = true;
    }

    //FETCH THE DATA FROM SERVER
    loadList(page) {
        this.receiptService
            .transactionList(this.itemFilter, page)
            .then(data => {
                this.lists = data.records;
                this.totalRecords = data.totalRecords;
                this.limit = data.limit;
                this.statement = data.statement;
            });
    }

    loadSearchData() {
        this.submitted = true;
        if (this.itemFilter.keyword) {
            this.goToFirstLink = 0;
            this.loadList(this.goToFirstLink);
        }
    }

    updateFilterStatus(obj) {
        if (obj != null && obj.loading == "Yes") {
            this.itemFilter.filterType = obj.filterName.value;
            this.itemFilter.storeID = obj.storeID.id;
            this.itemFilter.date1 = obj.date1;
            this.itemFilter.date2 = obj.date2;
            this.itemFilter.keyword = "";
            this.goToFirstLink = 0;
            this.loadList(this.goToFirstLink);
        }
        this.filterDialog = false;
    }

    calculateBalance(totalBill, receiptBalance) {
        let totalAmount = 0;
        receiptBalance.forEach(e => {
            totalAmount = totalAmount + Number(e.trans_total_amount);
        });
        return Number(totalBill - totalAmount);
    }

    openTransactionDialog() {
        this.previewTransactionDialog = true;
        this.menuBar = false;
    }

    openPaymentScreen() {
        this.paymentDialog = true;
        this.menuBar = false;
        this.dialogTitle = "Receive Or Pay Receipt Dues";

        if (Number(this.totalBalanceReceipt) > 0) {
            this.paymentDialog = true;
            this.store.dispatch(
                ActionTypes.TOTAL_BILL,
                Number(this.formatAmount(this.totalBalanceReceipt))
            );
        } else {
            this.toast.showError(
                "Receipt balance is not valid to proceed next"
            );
        }
    }

    openMenu(data) {
        this.menuBar = true;
        this.selectedReceiptData.paymentTransactions = this.camelizeKeys(
            data.receipt_balance
        );
        this.item.type = data.type;
        this.item.profileID = data.profile_id;
        this.item.id = data.id;
        this.item.status = data.status;
        this.item.selectedProfile = data.profile_name.profileName;
        // this.paymentList = data.payment_transactions;
        this.totalBalanceReceipt = this.calculateBalance(
            data.total_bill,
            data.receipt_balance
        );
    }

    closePaymentScreen() {
        this.paymentDialog = false;
    }

    getProceededPayments(paymentList) {
        this.paymentList = paymentList;

        this.setAccountingEntries();

        this.receiptService
            .savePayment(this.item, this.paymentList, this.counterEntry)
            .then(res => {
                if (res.alert == "info") {
                    this.loadList(this.goToFirstLink);
                    this.clearAll();
                }

                this.toast.handleResponse(res);
            });

        this.paymentDialog = false;
        this.submitted = false;
    }

    stockLeft() {
        this.receiptService.stockLeft(this.item).then(res => {
            if (res.alert == "info") {
                this.loadList(this.goToFirstLink);
                this.clearAll();
            }

            this.toast.handleResponse(res);
        });

        this.menuBar = false;
    }

    saveStock() {
        this.receiptService.saveStock(this.item).then(res => {
            if (res.alert == "info") {
                this.loadList(this.goToFirstLink);
                this.clearAll();
            }

            this.toast.handleResponse(res);
        });

        this.menuBar = false;
    }

    getPaymentMwthod(paymnetList) {
        let method = "";

        if (paymnetList.length == 0) {
            method = "Pay Later";
        } else if (paymnetList.length == 1) {
            method = paymnetList[0].paymentType;
        } else if (paymnetList.length > 1) {
            method = "Split";
        }

        return method;
    }
    voidReceipt() {
        this.receiptService.voidStock(this.item).then(res => {
            if (res.alert == "info") {
                this.loadList(this.goToFirstLink);
                this.clearAll();
            }

            this.toast.handleResponse(res);
        });

        this.menuBar = false;
    }

    clearAll() {
        this.paymentList = [];

        this.item = {
            id: 0,
            profileID: 0,
            selectedProfile: "",
            type: "",
            status: ""
        };
    }

    updatePreviewStatus() {
        this.previewPosReceipt = false;
    }

    previewDialog() {
        this.receiptID = this.item.id;
        this.previewPosReceipt = true;
        this.menuBar = false;
    }

    get totalPaidCash() {
        let total = 0;

        this.paymentList.forEach(e => {
            if (e.paymentType == "Cash") {
                total = total + e.transTotalAmount;
            }
        });

        return total;
    }

    get totalPaidBank() {
        let total = 0;

        this.paymentList.forEach(e => {
            if (e.paymentType != "Cash") {
                total = total + e.transTotalAmount;
            }
        });

        return total;
    }

    setAccountingEntries() {
        this.counterEntry = [];

        let totalPaid = this.totalPaidCash + this.totalPaidBank;

        if (totalPaid > 0) {
            if (
                this.item.type == "INE" ||
                this.item.type == "TRN" ||
                this.item.type == "RPU"
            ) {
                if (this.totalPaidCash > 0) {
                    this.counterEntry.push({
                        accountID: 2,
                        accountHead: "Cash in hand",
                        amount: this.totalPaidCash,
                        type: "Debit"
                    });
                }

                if (this.totalPaidBank > 0) {
                    this.counterEntry.push({
                        accountID: 8,
                        accountHead: "Cash at bank",
                        amount: this.totalPaidBank,
                        type: "Debit"
                    });
                }

                this.counterEntry.push({
                    accountID: 4,
                    accountHead: "Accounts receivable",
                    amount: totalPaid,
                    type: "Credit"
                });
            } else if (this.item.type == "RFD" || this.item.type == "PUR") {
                this.counterEntry.push({
                    accountID: 5,
                    accountHead: "Accounts payable",
                    amount: totalPaid,
                    type: "Debit"
                });

                if (this.totalPaidCash > 0) {
                    this.counterEntry.push({
                        accountID: 2,
                        accountHead: "Cash in hand",
                        amount: this.totalPaidCash,
                        type: "Credit"
                    });
                }

                if (this.totalPaidBank > 0) {
                    this.counterEntry.push({
                        accountID: 8,
                        accountHead: "Cash at bank",
                        amount: this.totalPaidBank,
                        type: "Credit"
                    });
                }
            } else {
                //TRANSFER ENTRY YET NEEDS TO BE DONE
            }
        }
    }

    get currency() {
        return this.store.getters.getCurrency;
    }
}
