
import { Options, mixins } from "vue-class-component";
import TabMenu from "primevue/tabmenu";
import router from "../router";
import { ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import UtilityOptions from "../mixins/UtilityOptions";

@Options({
  components: {
    TabMenu,
  },
})
export default class Header extends mixins(UtilityOptions) {
  get menuItems() {
    const newList = this.items.filter((e) => {
      const p = this.can(e.name);
      //const p = true;
      if ((e.name != "" && p == true) || e.name == "Settings") return e;
    });

    newList.forEach((e) => {
      if (e.items != undefined) {
        e.items = e.items.filter((e) => {
          const p = this.can(e.name);
          if ((e.name != "" && p == true) || e.name == "Logout") {
            return e;
          }
        });
      }
    });

    return newList;
  }

  private items = [
    {
      label: "Dashboard",
      name: "Dashboard",
      icon: "pi pi-fw pi-home",
      to: "/store/dashboard",
    },
    {
      label: "Transfer",
      name: "Transfer",
      icon: "pi pi-fw pi-shopping-cart",
      to: "/pos",
    },
    {
      label: "Purchases",
      name: "Purchasing",
      icon: "pi pi-fw pi-table",
      to: "/purchasing",
    },
    {
      label: "Transactions",
      name: "Transaction Receipt",
      icon: "pi pi-fw pi-window-maximize",
      to: "/process/transactions",
    },
    {
      label: "Driver Scan",
      name: "Driver Scan",
      icon: "pi pi-fw pi-car",
      to: "/process/driver-list",
    },
    {
      label: "Admin Track",
      name: "Admin Track",
      icon: "pi pi-fw pi-car",
      to: "/process/admin-track",
    },
    {
      label: "Plant Scan",
      name: "Plant Scan",
      icon: "pi pi-fw pi-building",
      to: "/process/plant-list",
    },
    {
      label: "Store Scan",
      name: "Store Scan",
      icon: "pi pi-fw pi-stop",
      to: "/process/store-list",
    },
    {
      label: "Scan Items",
      name: "Scan Items",
      icon: "pi pi-fw pi-bars",
      to: "/process/scan-items",
    },
    {
      label: "Settings",
      name: "Settings",
      icon: "pi pi-fw pi-cog",
      items: [
        {
          label: "Initialization",
          name: "Initialization",
          icon: "pi pi-fw pi-plus-circle",
          to: "/store/initialization",
        },
        {
          label: "Reports",
          name: "Report",
          icon: "pi pi-fw pi-chart-bar",
          to: "/store/reports",
        },
        {
          label: "Logout",
          name: "Logout",
          icon: "pi pi-fw pi-power-off",
          command: () => this.logOut(),
        },
      ],
    },
  ];

  logOut() {
    const toast = new Toaster();

    const res = {
      alert: "info",
      msg: "Sign out successfully",
    };

    toast.handleResponse(res);

    this.store.dispatch(ActionTypes.AUTH_LOGOUT, "");

    router.push({ path: "/login" });
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }
}
