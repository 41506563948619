import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9e775c1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-container" }
const _hoisted_2 = { class: "section-menu" }
const _hoisted_3 = { class: "col-md-12" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "menu-link-settings" }
const _hoisted_7 = { class: "menu-link-detail-settings" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Toolbar, null, {
      start: _withCtx(() => [
        _createVNode(_component_Breadcrumb, {
          home: _ctx.home,
          model: _ctx.items,
          class: "p-menuitem-text"
        }, null, 8, ["home", "model"])
      ]),
      _: 1
    }),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.initializationList, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: item }, [
              (_ctx.can(item.label) == true)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: item.to,
                    class: "col-12 col-md-3 text-center hover-settings"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        class: "menu-img-settings",
                        src: item.image
                      }, null, 8, _hoisted_5),
                      _createElementVNode("h5", _hoisted_6, _toDisplayString(item.label), 1),
                      _createElementVNode("p", _hoisted_7, _toDisplayString(item.description), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ])
      ])
    ])
  ]))
}