
import { Options, mixins } from "vue-class-component";
import HomepageService from "../../service/HomepageService";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import { camelCase } from "lodash";
import UtilityOptions from "../../mixins/UtilityOptions";

interface ChartTypes {
  name: string;
  amount: number;
}

interface RevenueTypes {
  totalExpense: number;
  totalRevenue: number;
  transMonth: string;
}

@Options({
  components: {},
  title: "Dashboard",
})
export default class Homepage extends mixins(UtilityOptions) {
  private stores = [];
  private branchName = "";
  private branchID = "";

  private dashInfo = {
    shortageQty: 0,
    accountHolders: 0,
    monthlyExpense: 0,
    itemsQty: 0,
    salesToday: 0,
    salesQty: 0,
    salesMonth: 0,
    returnToday: 0,
    returnMonth: 0,
    expenseToday: 0,
    purchaseToday: 0,
    purchaseMonth: 0,
    purchaseReturnToday: 0,
    purchaseReturnMonth: 0,
    transferToday: 0,
    transferMonth: 0,
    cashInToday: 0,
    cashInMonth: 0,
    cashAtBankToday: 0,
    cashAtBankMonth: 0,
  };

  private recentlyItems = [];
  private revenueExpense: RevenueTypes[] = [];
  private monthList = [];
  private topBrands: ChartTypes[] = [];
  private topUsers: ChartTypes[] = [];
  private topCustomers: ChartTypes[] = [];
  private homepageTxn;
  private toast;

  private multiAxisOptions = {
    responsive: true,
    tooltips: {
      mode: "index",
      titleFontSize: 30,
      bodyFontSize: 22,
      intersect: true,
    },
  };

  private storeActivityChartOptions = {
    responsive: true,
    tooltips: {
      mode: "index",
      titleFontSize: 30,
      bodyFontSize: 22,
      intersect: true,
    },
    scales: {
      // yAxes: [
      //   {
      //     type: "linear",
      //     display: false,
      //     position: "left",
      //     id: "y-axis-1",
      //   },
      // ],
    },
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.homepageTxn = new HomepageService();
    this.toast = new Toaster();
  }

  mounted() {
    this.getHomepageDetails();
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  getHomepageDetails() {
    this.homepageTxn.getHompageDetails(this.branchID).then((res) => {
      const d = this.camelizeKeys(res);

      this.topBrands = d.topBrandsAmount;
      this.topUsers = d.topUsers;
      this.topCustomers = d.topCustomers;

      this.dashInfo.shortageQty = d.shortageQty;
      this.dashInfo.accountHolders = d.accountHolders;
      this.dashInfo.monthlyExpense = d.monthlyExpense;
      this.dashInfo.itemsQty = d.itemsQty;
      this.dashInfo.salesToday = d.salesToday;
      this.dashInfo.salesQty = d.salesQty;
      this.dashInfo.salesMonth = d.salesMonth;
      this.dashInfo.returnToday = d.returnToday;
      this.dashInfo.returnMonth = d.returnMonth;
      this.dashInfo.expenseToday = d.expenseToday;
      this.dashInfo.purchaseToday = d.purchaseReturnToday;
      this.dashInfo.purchaseMonth = d.purchaseMonth;
      this.dashInfo.purchaseReturnToday = d.purchaseReturnToday;
      this.dashInfo.purchaseReturnMonth = d.purchaseReturnMonth;
      this.dashInfo.transferToday = d.transferToday;
      this.dashInfo.transferMonth = d.transferMonth;
      this.dashInfo.cashInToday = d.cashInToday;
      this.dashInfo.cashInMonth = d.cashInMonth;
      this.dashInfo.cashAtBankToday = d.cashAtBankToday;
      this.dashInfo.cashAtBankMonth = d.cashAtBankMonth;
      this.recentlyItems = d.recentlyAdded;
      this.branchName = d.storeInfo.name;
      //this.branchID                     = d.storeInfo.id;

      this.monthList = d.lastMonths;
      this.revenueExpense = d.revenueExpense;
      this.stores = d.stores;
    });
  }

  get getRevenueExpenseChart() {
    let monthName: string[] = [];
    let revenues: number[] = [];
    let expenses: number[] = [];
    let profit: number[] = [];

    this.monthList.map((m) => {
      const s = moment(m, "MM").format("MMM");
      const i = this.revenueExpense.filter(
        (n) => Number(m) == Number(n.transMonth)
      );

      if (i.length > 0) {
        const r = i[0].totalRevenue;
        const e = i[0].totalExpense;
        revenues.push(this.formatAmount(r));
        expenses.push(this.formatAmount(e));
        profit.push(this.formatAmount(r - e));
      } else {
        revenues.push(0);
        expenses.push(0);
        profit.push(0);
      }

      monthName.push(s);
    });

    return [monthName, revenues, expenses, profit];
  }

  get sortBand() {
    let names: string[] = [];
    let amount: number[] = [];

    if (this.topBrands != null) {
      const sorted = this.topBrands.slice(0);
      sorted.sort(function (a, b) {
        return a.amount - b.amount;
      });

      sorted.map((e) => {
        amount.push(e.amount);
        names.push(e.name);
      });

      amount = amount.reverse().slice(0, 4);
      names = names.reverse().slice(0, 4);
    }

    return [names, amount];
  }

  get sortCustomers() {
    let names: string[] = [];
    let amount: number[] = [];

    if (this.topCustomers != null) {
      const sorted = this.topCustomers.slice(0);
      sorted.sort(function (a, b) {
        return a.amount - b.amount;
      });

      sorted.map((e) => {
        amount.push(e.amount);
        names.push(e.name);
      });

      amount = amount.reverse().slice(0, 4);
      names = names.reverse().slice(0, 4);
    }
    return [names, amount];
  }

  get sortUsers() {
    let names: string[] = [];
    let amount: number[] = [];

    if (this.topUsers != null) {
      const sorted = this.topUsers.slice(0);
      sorted.sort(function (a, b) {
        return a.amount - b.amount;
      });

      sorted.map((e) => {
        amount.push(e.amount);
        names.push(e.name);
      });

      amount = amount.reverse().slice(0, 4);
      names = names.reverse().slice(0, 4);
    }

    return [names, amount];
  }

  get brandChartData() {
    return {
      labels: this.sortBand[0],
      datasets: [
        {
          label: "Top Brands " + this.currency,
          backgroundColor: "#004C97",
          yAxisID: "y-axis-1",
          data: this.sortBand[1],
        },
      ],
    };
  }

  get customersChart() {
    return {
      labels: this.sortCustomers[0],
      datasets: [
        {
          label: "Top Customers " + this.currency,
          backgroundColor: "#28a745",
          yAxisID: "y-axis-1",
          data: this.sortCustomers[1],
        },
      ],
    };
  }

  get usersChart() {
    return {
      labels: this.sortUsers[0],
      datasets: [
        {
          label: "Top Users " + this.currency,
          backgroundColor: "#ffc107",
          yAxisID: "y-axis-1",
          data: this.sortUsers[1],
        },
      ],
    };
  }

  get multiAxisData() {
    return {
      labels: this.getRevenueExpenseChart[0],
      datasets: [
        {
          type: "bar",
          label: "Revenues " + this.currency,
          backgroundColor: "#004C97",
          data: this.getRevenueExpenseChart[1],
        },
        {
          type: "bar",
          label: "Expenses " + this.currency,
          backgroundColor: "#ffc107",
          data: this.getRevenueExpenseChart[2],
        },
        {
          type: "line",
          label: "Profit " + this.currency,
          backgroundColor: "#20c997",
          data: this.getRevenueExpenseChart[3],
        },
      ],
    };
  }

  get currency() {
    return this.store.getters.getCurrency;
  }
}
