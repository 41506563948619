import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';

export default class DcService {

    getUserRoleName() {
        //SHOW LOADING
        const store = useStore();
        store.dispatch(ActionTypes.PROGRESS_BAR, true);
        const api = '/api/order_role_name';
        return instance()(
            {
                method: 'get',
                url: api,
            }
        ).then(res => res.data)
            .catch((e) => ExceptionHandling.HandleErrors(e))
            .finally(() => {
                store.dispatch(ActionTypes.PROGRESS_BAR, false);
            })
    }

    searchItem(keyword) {
        //SHOW LOADING
        const store = useStore();
        store.dispatch(ActionTypes.PROGRESS_BAR, true);
        const api = '/api/search_items';
        const formData = new FormData();
        formData.append('keyword', keyword);

        return instance()(
            {
                method: 'post',
                url: api,
                data: formData,
            }
        ).then(res => res.data)
            .catch((e) => ExceptionHandling.HandleErrors(e))
            .finally(() => {
                store.dispatch(ActionTypes.PROGRESS_BAR, false);
            })
    }

    saveItem(postObj, driverLocation) {
        //SHOW LOADING
        const store = useStore();
        store.dispatch(ActionTypes.PROGRESS_BAR, true);
        const api = '/api/save_scan_item';
        const formData = new FormData();
        formData.append('item_list', JSON.stringify(postObj));
        formData.append('driverLocation', driverLocation);
        return instance()(
            {
                method: 'post',
                url: api,
                data: formData,
            }
        ).then(res => res.data)
            .catch((e) => ExceptionHandling.HandleErrors(e))
            .finally(() => {
                store.dispatch(ActionTypes.PROGRESS_BAR, false);
            })
    }

    checkItem(postObj, driverLocation) {
        //SHOW LOADING
        const store = useStore();
        store.dispatch(ActionTypes.PROGRESS_BAR, true);
        const api = '/api/check_scan_item';
        const formData = new FormData();
        formData.append('item_list', JSON.stringify(postObj));
        formData.append('driverLocation', driverLocation);
        return instance()(
            {
                method: 'post',
                url: api,
                data: formData,
            }
        ).then(res => res.data)
            .catch((e) => ExceptionHandling.HandleErrors(e))
            .finally(() => {
                store.dispatch(ActionTypes.PROGRESS_BAR, false);
            })
    }

    getOrders() {
        //SHOW LOADING
        const store = useStore();
        store.dispatch(ActionTypes.PROGRESS_BAR, true);
        const api = '/api/order-track';
        return instance().get(api)
            .then(res => res.data)
            .catch((e) => ExceptionHandling.HandleErrors(e))
            .finally(() => {
                store.dispatch(ActionTypes.PROGRESS_BAR, false);
            })
    }

    driverList(postObj) {
        //SHOW LOADING
        const store = useStore();
        store.dispatch(ActionTypes.PROGRESS_BAR, true);
        const api = '/api/order_driver_list';
        const formData = new FormData();
        formData.append('filters', JSON.stringify(postObj));
        return instance()(
            {
                method: 'post',
                url: api,
                data: formData,
            }
        ).then(res => res.data)
            .catch((e) => ExceptionHandling.HandleErrors(e))
            .finally(() => {
                store.dispatch(ActionTypes.PROGRESS_BAR, false);
            })
    }

    adminList(postObj) {
        //SHOW LOADING
        const store = useStore();
        store.dispatch(ActionTypes.PROGRESS_BAR, true);
        const api = '/api/order_admin_list';
        const formData = new FormData();
        formData.append('filters', JSON.stringify(postObj));
        return instance()(
            {
                method: 'post',
                url: api,
                data: formData,
            }
        ).then(res => res.data)
            .catch((e) => ExceptionHandling.HandleErrors(e))
            .finally(() => {
                store.dispatch(ActionTypes.PROGRESS_BAR, false);
            })
    }

    userList() {
        //SHOW LOADING
        const store = useStore();
        store.dispatch(ActionTypes.PROGRESS_BAR, true);
        const api = '/api/order_user_list';

        return instance()(
            {
                method: 'get',
                url: api,
            }
        ).then(res => res.data)
            .catch((e) => ExceptionHandling.HandleErrors(e))
            .finally(() => {
                store.dispatch(ActionTypes.PROGRESS_BAR, false);
            })
    }

    plantList(postObj) {
        //SHOW LOADING
        const store = useStore();
        store.dispatch(ActionTypes.PROGRESS_BAR, true);
        const api = '/api/order_plant_list';
        const formData = new FormData();
        formData.append('filters', JSON.stringify(postObj));
        return instance()(
            {
                method: 'post',
                url: api,
                data: formData,
            }
        ).then(res => res.data)
            .catch((e) => ExceptionHandling.HandleErrors(e))
            .finally(() => {
                store.dispatch(ActionTypes.PROGRESS_BAR, false);
            })
    }

    storeList(postObj) {
        //SHOW LOADING
        const store = useStore();
        store.dispatch(ActionTypes.PROGRESS_BAR, true);
        const api = '/api/order_store_list';
        const formData = new FormData();
        formData.append('filters', JSON.stringify(postObj));
        return instance()(
            {
                method: 'post',
                url: api,
                data: formData,
            }
        ).then(res => res.data)
            .catch((e) => ExceptionHandling.HandleErrors(e))
            .finally(() => {
                store.dispatch(ActionTypes.PROGRESS_BAR, false);
            })
    }

    deleteReceipt(id) {
        //SHOW LOADING
        const store = useStore();
        store.dispatch(ActionTypes.PROGRESS_BAR, true);
        const api = '/api/order_delete_receipt';
        const formData = new FormData();
        formData.append('id', id);
        return instance()(
            {
                method: 'post',
                url: api,
                data: formData,
            }
        ).then(res => res.data)
            .catch((e) => ExceptionHandling.HandleErrors(e))
            .finally(() => {
                store.dispatch(ActionTypes.PROGRESS_BAR, false);
            })
    }

    singleHistory(id) {
        //SHOW LOADING
        const store = useStore();
        store.dispatch(ActionTypes.PROGRESS_BAR, true);
        const api = '/api/single_history';
        const formData = new FormData();
        formData.append('id', id);
        return instance()(
            {
                method: 'post',
                url: api,
                data: formData,
            }
        ).then(res => res.data)
            .catch((e) => ExceptionHandling.HandleErrors(e))
            .finally(() => {
                store.dispatch(ActionTypes.PROGRESS_BAR, false);
            })
    }
}