
import { Options, Vue } from "vue-class-component";
import PosService from "../../service/PosService.js";
import ProfilerService from "../../service/ProfilerService.js";
import ChartService from "../../service/ChartService.js";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import AutoComplete from "primevue/autocomplete";
import SearchFilter from "../../components/SearchFilter.vue";
import PreviewAccountingReceipt from "../../components/PreviewAccountingReceipt.vue";
import { ref, defineComponent, toRefs, reactive } from "vue";
import PaymentScreen from "../../components/PaymentScreen.vue";
import { useStore, ActionTypes } from "../../store";
import ProfilerDialog from "../../components/ProfilerDialog.vue";
import { ItemList } from "../pos_receipt/IPosReceipt";
import router from "../../router";

interface PaymentListType {
  paymentType: string;
  accountNo: string;
  terminalId: string;
  authCode: string;
  transId: string;
  transStatus: string;
  transType: string;
  transDate: string;
  transTime: string;
  transAmount: number;
  transTotalAmount: number;
  transRef: string;
  entryMode: string;
  hostResponse: string;
  giftCardRef: string;
  cardBalance: string;
  tendered: number;
  change: number;
  roundOff: number;
}

interface CounterEntry {
  accountID: number;
  accountHead: string;
  amount: number;
  type: string;
}

@Options({
  title: "Orders",
  components: {
    AutoComplete,
    SearchFilter,
    PreviewAccountingReceipt,
    PaymentScreen,
    ProfilerDialog,
  },
})
export default class PosReceipt extends Vue {
  private modeList = [{ label: "TRANSFER", value: "TRN" }];

  private unitType = ["Pack", "Unit"];

  private paymentDialog = false;
  private profileStatus = false;
  private statusType = "New";
  private storeName = "Loading...";
  private refundReceiptDialog = false;
  private submitted = false;
  private defaultCustomerName = "";
  private defaultCustomerID = 0;
  private currentUserID = 0;
  private itemScanBox = "";
  private dialogTitle = "";
  private screenMode = "minimize";
  private profilerService;
  private posService;
  private toast;
  private storeList = [];
  private profilerList = [];
  private itemList = [];
  private store = useStore();

  private counterEntry: CounterEntry[] = [];

  private screenState = reactive({
    fullscreen: false,
    teleport: true,
    pageOnly: false,
  });

  private selectedTaxes = {
    tax1: false,
    tax2: false,
    tax3: false,
  };

  private taxNames = [
    {
      taxName: "",
      show: false,
      optionalReq: "",
      taxValue: 0,
      accountHead: "",
      accountID: 0,
    },
    {
      taxName: "",
      show: false,
      optionalReq: "",
      taxValue: 0,
      accountHead: "",
      accountID: 0,
    },
    {
      taxName: "",
      show: false,
      optionalReq: "",
      taxValue: 0,
      accountHead: "",
      accountID: 0,
    },
  ];

  private state = reactive({
    selectedProfile: "",
    transferStoreID: "",
  });

  private savedItemList: ItemList[] = [];

  private validationRules = {
    selectedProfile: {
      required,
    },
    transferStoreID: {
      required: helpers.withMessage(
        "Transfer Store is required",
        requiredIf(() => {
          return this.item.type == "TRN";
        })
      ),
    },
  };

  private paymentList: PaymentListType[] = [];

  private item = {
    id: 0,
    profileID: this.defaultCustomerID,
    discount: 0,
    totalPaid: 0,
    totalTendered: 0,
    totalChange: 0,
    totalGrossAmt: 0,
    totalCost: 0,
    totalBill: 0,
    totalTax1: 0,
    totalTax2: 0,
    totalTax3: 0,
    totalTax: 0,
    description: "",
    paymentMethod: "",
    doctorDetails: "",
    patientDetails: "",
    searchReceiptNo: "",
    status: "Active",
    type: "TRN",
  };

  private v$ = useVuelidate(this.validationRules, this.state);

  //DEFAULT METHOD OF TYPE SCRIPT
  created() {
    this.profilerService = new ProfilerService();
    this.posService = new PosService();
    this.toast = new Toaster();
  }

  mounted() {
    this.loadList();
  }

  toggleScreen() {
    this.screenState.fullscreen = !this.screenState.fullscreen;
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  searchProfiler(event) {
    setTimeout(() => {
      this.profilerService.searchProfiler(event.query.trim()).then((data) => {
        this.profilerList = data.records;
      });
    }, 200);
  }

  searchItem(event) {
    setTimeout(() => {
      this.posService.searchItem(event.query.trim()).then((data) => {
        this.itemList = data.records;
      });
    }, 200);
  }

  saveProfile(event) {
    const profileInfo = event.value;
    this.state.selectedProfile = profileInfo.account_title;
    this.item.profileID = profileInfo.id;
  }

  saveItem(event) {
    const itemInfo = event.value;

    let sellRate = 0;
    let disc = 0;

    if (this.item.type != "TRN") {
      sellRate = itemInfo.sale_price;
      disc = itemInfo.discount_percentage;
    } else {
      sellRate = itemInfo.purchase_price;
      disc = 0;
    }

    this.savedItemList.push({
      mode: "Pack",
      stockID: itemInfo.id,
      productName: itemInfo.product_name,
      itemDescription: itemInfo.description,
      unit: 1,
      totalUnit: 0,
      stockQty: Number(itemInfo.qty),
      freeUnit: 0,
      supplierBonus: 0,
      batchNo: itemInfo.batch_no,
      packSize: Number(itemInfo.pack_size),
      purchasePrice: Number(itemInfo.purchase_price),
      originalPPrice: Number(itemInfo.purchase_price),
      originalSPrice: Number(sellRate),
      sellingPrice: Number(sellRate),
      mrp: Number(itemInfo.mrp),
      brandName: itemInfo.bName,
      sectorName: itemInfo.bSector,
      categoryName: itemInfo.cName,
      productType: itemInfo.pType,
      itemDisc: Number(disc),
      tax1: Number(itemInfo.tax_1),
      tax2: Number(itemInfo.tax_2),
      tax3: Number(itemInfo.tax_3),
      subTotal: 0,
    });

    this.itemScanBox = "";
  }

  loadList() {
    this.posService.getItems().then((data) => {
      // //taxNames
      this.taxNames = [];

      this.storeList = data.stores;

      this.taxNames.push({
        taxName: data.storeTaxes[0].tax_name_1,
        show: data.storeTaxes[0].show_1 == "true" ? true : false,
        optionalReq: data.storeTaxes[0].required_optional_1,
        taxValue:
          data.storeTaxes[0].show_1 == "true"
            ? Number(data.storeTaxes[0].tax_value_1)
            : 0,
        accountHead: data.storeTaxes[0].tax_name1.chartName,
        accountID: data.storeTaxes[0].link1,
      });

      this.taxNames.push({
        taxName: data.storeTaxes[0].tax_name_2,
        show: data.storeTaxes[0].show_2 == "true" ? true : false,
        optionalReq: data.storeTaxes[0].required_optional_2,
        taxValue:
          data.storeTaxes[0].show_2 == "true"
            ? Number(data.storeTaxes[0].tax_value_2)
            : 0,
        accountHead: data.storeTaxes[0].tax_name2.chartName,
        accountID: data.storeTaxes[0].link2,
      });

      this.taxNames.push({
        taxName: data.storeTaxes[0].tax_name_3,
        show: data.storeTaxes[0].show_3 == "true" ? true : false,
        optionalReq: data.storeTaxes[0].required_optional_3,
        taxValue:
          data.storeTaxes[0].show_3 == "true"
            ? Number(data.storeTaxes[0].tax_value_3)
            : 0,
        accountHead: data.storeTaxes[0].tax_name3.chartName,
        accountID: data.storeTaxes[0].link3,
      });

      if (
        this.taxNames[0].optionalReq == "Required" &&
        this.taxNames[0].show == true
      ) {
        this.selectedTaxes.tax1 = true;
      }

      if (
        this.taxNames[1].optionalReq == "Required" &&
        this.taxNames[1].show == true
      ) {
        this.selectedTaxes.tax2 = true;
      }

      if (
        this.taxNames[2].optionalReq == "Required" &&
        this.taxNames[2].show == true
      ) {
        this.selectedTaxes.tax3 = true;
      }

      //Default Customer
      this.state.selectedProfile = data.defaultCustomer.account_title;
      this.item.profileID = data.defaultCustomer.id;
      this.defaultCustomerName = data.defaultCustomer.account_title;
      this.defaultCustomerID = data.defaultCustomer.id;
      this.currentUserID = data.currentUserID;
      this.storeName = data.storeName;
    });
  }

  getTheSubtotal(data) {
    let totalUnits = this.sumUnitTotalQty(data);

    if (data.stockQty < totalUnits && this.item.type != "RFD") {
      data.unit = 0;
      data.mode = "Pack";
      data.freeUnit = 0;
      this.toast.showError("Item does not have enough stock qty for pack");
    }

    const qty = Number(data.unit);
    const price = Number(data.sellingPrice);
    const discount = Number(data.itemDisc);

    const tax1 = this.selectedTaxes.tax1 == true ? Number(data.tax1) : 0;
    const tax2 = this.selectedTaxes.tax2 == true ? Number(data.tax2) : 0;
    const tax3 = this.selectedTaxes.tax3 == true ? Number(data.tax3) : 0;

    const total = qty * price;
    const disAmount = (total / 100) * discount;

    const afterDis = total - disAmount;
    const afterTax = (afterDis / 100) * (tax1 + tax2 + tax3);

    const netTotal = afterDis + afterTax;

    data.subTotal = Number(this.fixDigits(netTotal));

    //TOTAL UNITS
    if (data.mode == "Pack") {
      data.totalUnit = data.packSize * data.unit + data.freeUnit;
    } else if (data.mode == "Strip") {
      data.totalUnit = data.sheetSize * data.unit + data.freeUnit;
    } else {
      data.totalUnit = data.unit + data.freeUnit;
    }

    return this.fixDigits(netTotal);
  }

  get totalGross() {
    let total = 0;
    this.savedItemList.forEach((e) => {
      total = total + e.sellingPrice * e.unit;
    });

    return total;
  }

  get totalCostInventory() {
    let total = 0;
    this.savedItemList.forEach((e) => {
      total = total + e.purchasePrice * e.unit;
    });

    return total;
  }

  get totalTax1() {
    let total = 0;

    this.savedItemList.forEach((e) => {
      const tax = this.selectedTaxes.tax1 == true ? Number(e.tax1) : 0;
      const price = e.sellingPrice * e.unit;
      const afterDisc = (price / 100) * e.itemDisc;
      total = total + ((price - afterDisc) / 100) * tax;
    });

    return Number(total.toFixed(2));
  }

  get totalTax2() {
    let total = 0;
    this.savedItemList.forEach((e) => {
      const tax = this.selectedTaxes.tax2 == true ? Number(e.tax2) : 0;
      const price = e.sellingPrice * e.unit;
      const afterDisc = (price / 100) * e.itemDisc;
      total = total + ((price - afterDisc) / 100) * tax;
    });

    return Number(total.toFixed(2));
  }

  get totalTax3() {
    let total = 0;
    this.savedItemList.forEach((e) => {
      const tax = this.selectedTaxes.tax3 == true ? Number(e.tax3) : 0;
      const price = e.sellingPrice * e.unit;
      const afterDisc = (price / 100) * e.itemDisc;
      total = total + ((price - afterDisc) / 100) * tax;
    });

    return Number(total.toFixed(2));
  }

  get totalDiscAmount() {
    let total = 0;
    this.savedItemList.forEach((e) => {
      const price = e.sellingPrice * e.unit;
      total = total + (price / 100) * e.itemDisc;
    });

    return total;
  }

  get netTotal() {
    return Number(
      (
        this.totalGross -
        this.totalDiscAmount +
        this.totalTax1 +
        this.totalTax2 +
        this.totalTax3
      ).toFixed(2)
    );
  }

  setPricing(data) {
    if (data.mode == "Pack") {
      data.sellingPrice = data.originalSPrice;
      data.purchasePrice = data.originalPPrice;
    } else if (data.mode == "Strip") {
      if (data.sheetSize != 0) {
        data.sellingPrice = data.originalSPrice / data.sheetSize;
        data.purchasePrice = data.originalPPrice / data.sheetSize;
      }
    } else {
      if (data.packSize != 0) {
        data.sellingPrice = data.originalSPrice / data.packSize;
        data.purchasePrice = data.originalPPrice / data.packSize;
      }
    }
  }

  fixDigits(amt) {
    let total = 0;

    if (amt != null) {
      total = amt.toFixed(2);
    }
    return total;
  }

  clearAll() {
    this.state.selectedProfile = this.defaultCustomerName;
    this.state.transferStoreID = "";

    this.savedItemList = [];
    this.paymentList = [];

    this.item = {
      id: 0,
      profileID: this.defaultCustomerID,
      discount: 0,
      totalGrossAmt: 0,
      totalCost: 0,
      totalBill: 0,
      totalPaid: 0,
      totalTendered: 0,
      totalChange: 0,
      totalTax1: 0,
      totalTax2: 0,
      totalTax3: 0,
      totalTax: 0,
      description: "",
      searchReceiptNo: "",
      paymentMethod: "",
      doctorDetails: "",
      patientDetails: "",
      status: "Active",
      type: this.item.type,
    };

    this.itemScanBox = "";
  }

  formatExpiryDate(d) {
    return moment(d).format("MMM-YYYY");
  }

  adjustQty(type, item) {
    if (type == "minus" && item.unit > 0) {
      item.unit--;
    } else if (type == "add") {
      if (
        item.unit + item.freeUnit < item.stockQty ||
        this.item.type == "RFD"
      ) {
        item.unit++;
      } else {
        this.toast.showError("Item does not have enough stock qty");
      }
    }
  }

  adjustFreeQty(type, item) {
    if (type == "minus" && item.freeUnit > 0) {
      item.freeUnit--;
    } else if (type == "add") {
      if (item.unit + item.freeUnit < item.stockQty) {
        item.freeUnit++;
      } else {
        this.toast.showError(
          "Item does not have enough stock qty to provide free units"
        );
      }
    }
  }

  clearListItem(item) {
    this.savedItemList.splice(this.savedItemList.indexOf(item), 1);
    this.toast.showSuccess("Item Deleted Successfully");
  }

  limitString(str) {
    if (str.length > 40) str = str.substring(0, 40) + "...";
    return str;
  }

  closePaymentScreen() {
    this.paymentDialog = false;
  }

  getProceededPayments(paymentList) {
    this.paymentList = paymentList;
    const tenderedList = this.getTotalPaid(paymentList);
    this.item.totalPaid = Number(tenderedList[0]);
    this.item.totalTendered = Number(tenderedList[1]);
    this.item.totalChange = Number(tenderedList[2]);

    const method = this.getPaymentMethod(paymentList);
    this.item.paymentMethod = method;

    this.item.discount = this.totalDiscAmount;
    this.item.totalGrossAmt = this.totalGross;
    this.item.totalCost = this.totalCostInventory;
    this.item.totalBill = this.netTotal;
    this.item.totalTax1 = this.totalTax1;
    this.item.totalTax2 = this.totalTax2;
    this.item.totalTax3 = this.totalTax3;
    this.item.totalTax = Number(
      this.totalTax1 + this.totalTax2 + this.totalTax3
    );

    this.setAccountingEntries();

    this.posService
      .saveItem(
        this.item,
        this.paymentList,
        this.state,
        this.savedItemList,
        this.counterEntry
      )
      .then((res) => {
        if (res.alert == "info") {
          this.clearAll();
        }

        this.toast.handleResponse(res);
      });

    this.paymentDialog = false;
    this.submitted = false;
  }

  openPaymentMethod(isFormValid) {
    this.submitted = true;
    if ((isFormValid = true)) {
      this.paymentDialog = true;
      this.store.dispatch(
        ActionTypes.TOTAL_BILL,
        Number(this.fixDigits(this.netTotal))
      );
    }
  }

  get totalPaidCash() {
    let total = 0;

    this.paymentList.forEach((e) => {
      if (e.paymentType == "Cash") {
        total = total + e.transTotalAmount;
      }
    });

    return total;
  }

  get totalPaidBank() {
    let total = 0;

    this.paymentList.forEach((e) => {
      if (e.paymentType != "Cash") {
        total = total + e.transTotalAmount;
      }
    });

    return total;
  }

  getTotalPaid(paymentList) {
    let totalPaid = 0;
    let totalTendered = 0;
    let totalChange = 0;

    paymentList.forEach((e) => {
      if (e.paymentType != "Tip") {
        totalPaid = totalPaid + Number(e.transTotalAmount);
        totalTendered = totalTendered + Number(e.tendered);
        totalChange = totalChange + Number(e.change);
      }
    });

    return [totalPaid, totalTendered, totalChange];
  }

  get totalBalance() {
    return this.netTotal - this.item.totalPaid;
  }

  getPaymentMethod(paymentList) {
    let method = "";

    if (paymentList.length == 0) {
      method = "Pay Later";
    } else if (paymentList.length == 1) {
      method = paymentList[0].paymentType;
    } else if (paymentList.length > 1) {
      method = "Split";
    }

    return method;
  }

  clearFreeUnit() {
    this.toast.showSuccess("Mode Changed Successfully");

    if (this.item.type != "TRN") {
      this.savedItemList.forEach((e) => {
        e.freeUnit = 0;
      });
    }

    if (this.item.type == "RFD") {
      this.refundReceiptDialog = true;
    }

    this.submitted = false;
    this.clearAll();
  }

  sumUnitTotalQty(data) {
    let totalUnits = 0;

    this.savedItemList.forEach((e) => {
      if (data.stockID == e.stockID) {
        if (e.mode == "Pack") {
          totalUnits = totalUnits + e.packSize * e.unit + e.freeUnit;
        } else {
          totalUnits = totalUnits + (e.freeUnit + e.unit);
        }
      }
    });

    return Number(totalUnits);
  }

  fetchReceiptNo() {
    this.posService.getReceiptItems(this.item.searchReceiptNo).then((data) => {
      if (data.receipt != null) {
        this.state.selectedProfile = data.receipt.profile_name.accountName;
        this.item.profileID = data.receipt.profile_name.id;
        this.item.patientDetails =
          data.receipt.patient_details == null
            ? ""
            : data.receipt.patient_details;
        this.item.description =
          data.receipt.description == null ? "" : data.receipt.description;
        this.item.doctorDetails =
          data.receipt.doctor_details == null
            ? ""
            : data.receipt.doctor_details;

        if (data.receipt.total_tax1 > 0) {
          this.selectedTaxes.tax1 = true;
        }

        if (data.receipt.total_tax2 > 0) {
          this.selectedTaxes.tax2 = true;
        }

        if (data.receipt.total_tax3 > 0) {
          this.selectedTaxes.tax3 = true;
        }
      }

      if (data.receiptItems != null) {
        data.receiptItems.forEach((e) => {
          this.savedItemList.push({
            mode: e.mode,
            stockID: Number(e.stock_id),
            productName: e.item_name,
            itemDescription: e.item_description,
            unit: Number(e.unit),
            totalUnit: Number(e.total_unit),
            stockQty: Number(e.stock_detail.qty),
            freeUnit: Number(e.free_unit),
            supplierBonus: Number(e.supplier_bonus),
            batchNo: e.batch_no,
            packSize: Number(e.pack_size),
            purchasePrice: Number(e.purchase_price),
            originalPPrice: Number(e.purchase_price),
            originalSPrice: Number(e.stock_detail.sale_price),
            sellingPrice: Number(e.selling_price),
            mrp: Number(e.mrp),
            brandName: e.brand_name,
            sectorName: e.sector_name,
            categoryName: e.category_name,
            productType: e.product_type,
            itemDisc: Number(e.item_disc),
            tax1: Number(e.tax_1),
            tax2: Number(e.tax_2),
            tax3: Number(e.tax_3),
            subTotal: Number(e.sub_total),
          });
        });

        this.refundReceiptDialog = false;
      }
    });
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openProfileDialog() {
    this.dialogTitle = "Add New Profile";
    this.profileStatus = true;
    this.statusType = "New";
  }

  updateProfilerStatus(res) {
    this.profileStatus = false;
    if (res[0] == "load") {
      this.state.selectedProfile = res[1].account_title;
      this.item.profileID = res[1].id;
    }
  }

  redirectHome() {
    this.screenState.fullscreen = false;
    router.replace({ path: "/store/dashboard", params: {} });
  }

  setAccountingEntries() {
    this.counterEntry = [];

    if (this.item.type == "INE") {
      if (this.totalBalance == 0) {
        if (this.totalPaidCash > 0) {
          this.counterEntry.push({
            accountID: 2,
            accountHead: "Cash in hand",
            amount: this.totalPaidCash,
            type: "Debit",
          });
        }

        if (this.totalPaidBank > 0) {
          this.counterEntry.push({
            accountID: 8,
            accountHead: "Cash at bank",
            amount: this.totalPaidBank,
            type: "Debit",
          });
        }
      } else if (this.totalBalance == this.netTotal) {
        this.counterEntry.push({
          accountID: 4,
          accountHead: "Accounts receivable",
          amount: this.netTotal,
          type: "Debit",
        });
      } else if (this.totalBalance > 0 && this.item.totalPaid > 0) {
        if (this.totalPaidCash > 0) {
          this.counterEntry.push({
            accountID: 2,
            accountHead: "Cash in hand",
            amount: this.totalPaidCash,
            type: "Debit",
          });
        }

        if (this.totalPaidBank > 0) {
          this.counterEntry.push({
            accountID: 8,
            accountHead: "Cash at bank",
            amount: this.totalPaidBank,
            type: "Debit",
          });
        }

        this.counterEntry.push({
          accountID: 4,
          accountHead: "Accounts receivable",
          amount: this.totalBalance,
          type: "Debit",
        });
      }

      this.counterEntry.push({
        accountID: 56,
        accountHead: "Cost of Goods",
        amount: this.totalCostInventory,
        type: "Debit",
      });

      this.counterEntry.push({
        accountID: 48,
        accountHead: "Sales Revenue",
        amount: this.totalGross - this.totalDiscAmount,
        type: "Credit",
      });

      this.counterEntry.push({
        accountID: 3,
        accountHead: "Inventory",
        amount: this.totalCostInventory,
        type: "Credit",
      });

      //ADDING TAXES
      if (this.totalTax1 != 0) {
        this.counterEntry.push({
          accountID: this.taxNames[0].accountID,
          accountHead: this.taxNames[0].accountHead,
          amount: this.totalTax1,
          type: "Credit",
        });
      }

      if (this.totalTax2 != 0) {
        this.counterEntry.push({
          accountID: this.taxNames[1].accountID,
          accountHead: this.taxNames[1].accountHead,
          amount: this.totalTax2,
          type: "Credit",
        });
      }

      if (this.totalTax3 != 0) {
        this.counterEntry.push({
          accountID: this.taxNames[2].accountID,
          accountHead: this.taxNames[2].accountHead,
          amount: this.totalTax3,
          type: "Credit",
        });
      }
    } else if (this.item.type == "RFD") {
      this.counterEntry.push({
        accountID: 48,
        accountHead: "Sales Revenue",
        amount: this.totalGross - this.totalDiscAmount,
        type: "Debit",
      });

      this.counterEntry.push({
        accountID: 3,
        accountHead: "Inventory",
        amount: this.totalCostInventory,
        type: "Debit",
      });

      //ADDING TAXES
      if (this.totalTax1 != 0) {
        this.counterEntry.push({
          accountID: this.taxNames[0].accountID,
          accountHead: this.taxNames[0].accountHead,
          amount: this.totalTax1,
          type: "Debit",
        });
      }

      if (this.totalTax2 != 0) {
        this.counterEntry.push({
          accountID: this.taxNames[1].accountID,
          accountHead: this.taxNames[1].accountHead,
          amount: this.totalTax2,
          type: "Debit",
        });
      }

      if (this.totalTax3 != 0) {
        this.counterEntry.push({
          accountID: this.taxNames[2].accountID,
          accountHead: this.taxNames[2].accountHead,
          amount: this.totalTax3,
          type: "Debit",
        });
      }

      if (this.totalBalance == 0) {
        if (this.totalPaidCash > 0) {
          this.counterEntry.push({
            accountID: 2,
            accountHead: "Cash in hand",
            amount: this.totalPaidCash,
            type: "Credit",
          });
        }

        if (this.totalPaidBank > 0) {
          this.counterEntry.push({
            accountID: 8,
            accountHead: "Cash at bank",
            amount: this.totalPaidBank,
            type: "Credit",
          });
        }
      } else if (this.totalBalance == this.netTotal) {
        this.counterEntry.push({
          accountID: 5,
          accountHead: "Accounts payable",
          amount: this.netTotal,
          type: "Credit",
        });
      } else if (this.totalBalance > 0 && this.item.totalPaid > 0) {
        if (this.totalPaidCash > 0) {
          this.counterEntry.push({
            accountID: 2,
            accountHead: "Cash in hand",
            amount: this.totalPaidCash,
            type: "Credit",
          });
        }

        if (this.totalPaidBank > 0) {
          this.counterEntry.push({
            accountID: 8,
            accountHead: "Cash at bank",
            amount: this.totalPaidBank,
            type: "Credit",
          });
        }

        this.counterEntry.push({
          accountID: 5,
          accountHead: "Accounts payable",
          amount: this.totalBalance,
          type: "Credit",
        });
      }

      this.counterEntry.push({
        accountID: 56,
        accountHead: "Cost of Goods",
        amount: this.totalCostInventory,
        type: "Credit",
      });
    } else {
      //TRANSFER ENTRY NO NEED
    }
  }

  get currency() {
    return this.store.getters.getCurrency;
  }
}
